<template lang="pug">
  .grid.grid-cols-4.gap-4.ml-64.mr-64.mx-auto
    .col-span-4
      app-panel
        form-wrapper(:validator="$v.credentials")
          .grid.grid-cols-1(@keyup.enter="login")
            .col-span-1
              app-header(size="h2") ManagePlaces Superadmin Login

            .col-span-1
              app-text-field(name="email" label="Email" v-model="credentials.email")

            .col-span-1
              app-text-field(name="password" label="Password" v-model="credentials.password" type="password")


          app-button(primary, @click="login") Login
</template>
<script>
import { required } from "vuelidate/lib/validators";
import LoginMutation from "@/graphql/mutations/superadmin_users/LoginMutation.gql";
export default {
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    credentials: {
      email: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    login: function() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$apollo
        .mutate({
          mutation: LoginMutation,
          variables: {
            input: {
              ...this.credentials,
            },
          },
        })
        .then(resp => {
          this.$store.dispatch("setCurrentUser", resp.data.login.user);
          this.$router.push("/companies");
          this.$flash.success(`Welcome back ${resp.data.login.user.name}`);
        });
    },
  },
};
</script>
<style lang="postcss" scoped>
.grid.grid-cols-3 {
  text-align: center;
}
</style>
